var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (data) {
            return [
              _c(
                "v-icon",
                {
                  on: {
                    click: function ($event) {
                      return _vm.onClick(data)
                    },
                  },
                },
                [_vm._v("mdi-content-copy")]
              ),
            ]
          },
        },
      ]),
    },
    [_c("span", [_vm._v("Copied!")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }