<template>
    <v-tooltip bottom>
        <template #activator="data">
            <v-icon @click="onClick(data)">mdi-content-copy</v-icon>
        </template>
        <span>Copied!</span>
    </v-tooltip>
</template>
<script>
export default {
    data: () => ({
        timeout: null,
    }),
    props: ["text"],
    methods: {
        hoge(data) {
            console.log(data);
        },
        onClick(data) {
            data.on.mouseenter();
            this.copyText();
            if(this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(()=>{
                data.on.mouseleave();
            },2000);
        },
        copyText() {
            navigator.clipboard.writeText(this.text);
        }
    }
}
</script>
